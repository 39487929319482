/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;0,600;1,400;1,500;1,600&display=swap');

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter';
  background: #F1F4FC;
}


:root {
  /* Colores Primarios y Secundarios */
  --color-primario: #EB6E24;
  --color-primario-10: #FFE6D7;
  /* 10% Más claro */
  --color-primario-20: #FFD4BA;
  /* 20% Más claro */
  --color-primario-30: #FFB599;
  /* 30% Más claro */
  --color-primario-40: #FFA875;
  /* 40% Más claro */
  --color-primario-50: #FA8D4D;
  /* 50% Más claro */
  --color-primario-60: #C45C1E;
  /* 60% Más oscuro */
  --color-primario-70: #AD4C12;
  /* 70% Más oscuro */

  --color-secundario: #0E525D;
  /* Color Base */
  --color-secundario-5: #E7F6F9;
  /* 5% Más claro */
  --color-secundario-10: #CFE5E9;
  /* 10% Más claro */
  --color-secundario-20: #ADD0D6;
  /* 20% Más claro */
  --color-secundario-30: #84B0B7;
  /* 30% Más claro */
  --color-secundario-40: #63979F;
  /* 40% Más claro */
  --color-secundario-50: #28727D;
  /* 50% Más claro */
  --color-secundario-60: #0E525D;
  /* 60% Más oscuro */
  --color-secundario-70: #08454F;
  /* 70% Más oscuro */

  /* Colores de Estado y sus Degradados */

  /* Información */
  --color-info: #2E78DD;
  /* Color Base */
  --color-info-10: #D5E4F8;
  /* 10% Más claro */
  --color-info-30: #97BCEE;
  /* 30% Más claro */
  --color-info-50: #74A5E8;
  /* 50% Más claro */
  --color-info-60: #2664B8;
  /* 60% Más oscuro */

  /* Éxito */
  --color-success: #21B660;
  /* Color Base */
  --color-success-10: #D3F0DF;
  /* 10% Más claro */
  --color-success-30: #90DDB0;
  /* 30% Más claro */
  --color-success-50: #6BCE95;
  /* 50% Más claro */
  --color-success-60: #1C9850;
  /* 60% Más oscuro */

  /* Alerta */
  --color-warning: #DBB339;
  /* Color Base */
  --color-warning-10: #F8F0D7;
  /* 10% Más claro */
  --color-warning-30: #EDD99C;
  /* 30% Más claro */
  --color-warning-50: #E7CC7B;
  /* 50% Más claro */
  --color-warning-60: #B79530;
  /* 60% Más oscuro */

  /* Error */
  --color-error: #D54C4C;
  /* Color Base */
  --color-error-10: #F7D8DB;
  /* 10% Más claro */
  --color-error-30: #EAA6A6;
  /* 30% Más claro */
  --color-error-50: #E38888;
  /* 50% Más claro */
  --color-error-60: #B23F3F;
  /* 60% Más oscuro */

  /* Colores de Texto */
  --color-black1: #211E16;
  --color-black2: #1D1D1D;
  --color-black3: #757575;
  --color-black4: #4E4E4E;
  --color-black5: #3E3E3E;

  /* Colores de Fondo */
  --color-gray1: #868EA1;
  --color-gray2: #868EA1;
  --color-gray3: #AAB1C2;
  --color-gray4: #C5CBD9;
  --color-gray5: #DEE1E9;
  --color-gray-soft: #BDBDBD;

  --color-modal-title: #405B75;

  --background-color: #F1F4FC;
}

.bg-primary {
  background-color: var(--color-primario);
}

.bg-secondary {
  background-color: var(--color-secundario);
}

.bg-primary10 {
  background-color: var(--color-primario-10);
}

.bg-primaryLight {
  background-color: var(--color-primario-20);
}

.border-primary {
  border-color: var(--color-primario);
}

.border-primary10 {
  border-color: var(--color-primario-10);
}

.border-primary20 {
  border-color: var(--color-primario-20);
}

/* .cdk-overlay-connected-position-bounding-box {
  z-index: 1003 !important;
} */

.ngx-ui-tour_backdrop {
  /* z-index: 1002 !important; */
  border-radius: 8px;
  padding: 4px;
  box-sizing: content-box;
  transform: translate(-4px, -4px);
  pointer-events: all;
}

.h-fit {
  height: fit-content;
}

.w-fit {
  width: fit-content;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1004 !important;
}

.custom-google-autocomplete-overlay {
  z-index: 1004 !important;
  position: absolute !important;
}

/* .blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001 !important;
  pointer-events: all;
} */

.evalRED {
  color: red;
  font-weight: 500;
}

.evalGREEN {
  color: green;
  font-weight: 500;
}

.evalYELLOW {
  color: #FFC107;
  font-weight: 500;
}

.evalGRAY {
  color: gray;
  font-weight: 500;
}

.mat-menu-panel {
  min-height: 0 !important;
}

.main-background {
  background-color: var(--background-color);
}

.text-primary {
  color: var(--color-primario);
}

.text-secondary {
  color: var(--color-secundario);
}

.text-form-green {
  color: #28727D !important;
}

.custom-menu-login {
  min-width: var(--menu-width) !important;
  width: var(--menu-width) !important;
}

.searcher .mat-select-search-input {
  padding: 10px !important;
  font-size: 14px !important;
}

.searcher .material-icons {
  font-size: 20px !important;
  color: var(--color-primario);
}

.searcher .mat-select-search-clear {
  top: 0 !important;
  border-radius: 50% !important;
}

.searcher .mat-select-search-no-entries-found {
  font-size: 14px !important;
}

.mat-table {
  display: grid;
  gap: 8px;
  background-color: var(--background-color);
}

.mat-row .mat-cell:nth-child(2) {
  font-weight: 600;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
}

.mat-row {
  border: 1px solid var(--color-gray4);
  margin-bottom: 2px;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}

.mat-header-row {
  background-color: var(--color-secundario-50);
  border-radius: 8px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  font-family: 'Inter', sans-serif !important;
  justify-content: flex-start !important;
}

.mat-header-cell {
  overflow: hidden;
  color: white;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Inter', sans-serif !important;
  justify-content: flex-start !important;
}

.mat-header-row .mat-header-cell:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mat-header-row .mat-header-cell:first-child::before {
  content: "";
  display: inline-block;
  margin-left: 16px;
}

.mat-paginator {
  background-color: var(--background-color);
}

.gray-title {
  color: var(--color-black3);
  font-family: 'Gotham', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.edit-text {
  color: var(--color-primario);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.edit-text2 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.wizard-container {
  border: 1px solid var(--color-gray5);
  border-radius: 8px;
  padding: 0;
}

.wizard-header {
  border-bottom: 1px solid var(--color-gray5);
  border-color: var(--color-gray5);
  width: 100%;
  padding: 0.75rem;
  font-size: 1.25rem;
  margin: 0;
}

.title-text {
  font-family: 'Gotham', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-black3);
}

.title-text-modal {
  font-family: 'Gotham', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-secundario-50);
}

.title-candidate-profile {
  color: var(--color-black5);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.title-input-text {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: var(--color-black3);
}

.subdomain-input-text {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: var(--color-primario);
}

.subtitle-input-text {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: var(--color-black3);
}

.input-style {
  border-radius: 8px;
  padding: 8px;
  margin: 4px 0;
  width: 100%;
  height: 43px;
  box-sizing: border-box;
  border: 1px solid var(--color-gray3);
}

.green-title {
  color: var(--color-secundario-50);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.green-subtitle {
  font-family: 'Gotham', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-secundario-50);
}

.error-title {
  color: var(--color-error-60);
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.hover-bg-color {
  display: inline-block;
  padding: 4px;
  padding-left: 10px;
  padding-right: 1px;
  border-radius: 8px;
  box-sizing: border-box;

  &:hover {
    background-color: var(--color-primario-10) !important;
  }
}

.mat-dropdown {
  border: 1px solid var(--color-gray3);
  border-radius: 8px;
}


@media (max-width: 768px) {
  .input-style {
    width: 100%;
  }
}

.mat-header-row .mat-header-cell:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mat-cell {
  color: var(--color-black3);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.actions-text {
  color: var(--color-black3);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.modal-title {
  font-family: "Gotham";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-modal-title);
}

.modal-subtitle {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-modal-title);
}



.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  opacity: 0.5 !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: white;
  border: 2px solid var(--color-primario-50);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-primario-50);
}

.mat-slide-toggle.mat-primary .mat-slide-toggle-thumb {
  background-color: white;
  border: 2px solid rgba(0, 0, 0, .38);
}

.mat-slide-toggle.mat-secondary.mat-checked .mat-slide-toggle-thumb {
  background-color: white;
  border: 2px solid var(--color-secundario-50);
}

.mat-slide-toggle.mat-secondary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-secundario-50);
}

.mat-slide-toggle.mat-secondary .mat-slide-toggle-thumb {
  background-color: white;
  border: 2px solid rgba(0, 0, 0, .38);
}

.mat-slide-toggle .mat-slide-toggle-label {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
}

.mat-slide-toggle {
  line-height: unset !important;
}

::ng-deep .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

.mat-form-field-outline-start,
.mat-form-field-outline-end {
  border-radius: 0 !important;
}

.mat-button {
  border-radius: 0 !important;
}

.borderbottom {
  border-bottom: 1px solid #EBEBEB
}

.gray {
  color: #718096;
}

.grayText {
  color: #728096;
}

.blueTitle {
  color: #06589F;
}

.borde {
  border: 1px solid;
  border-color: #EBEBEB;
  border-radius: 8px;
}

.backcyan {
  background-color: #28B7FA;
}

.successLabel {
  color: white;
  background-color: #00B92D;
}

.errorLabel {
  color: white;
  background-color: #fa284f;
}

.pendingLabel {
  color: white;
  background-color: lightgray;
}

a {
  cursor: pointer;
}

.bg-custom-blue {
  background-color: #06589f;
}

.bg-custom-blue:hover {
  background-color: #054a7d;
  /*  hover */
}

.bg-custom-blue:focus {
  background-color: #054a7d;
  /* Un  focus */
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: var(--color-primario);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primario);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--color-primario);
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--color-primario);
}

.mat-dialog-container {
  scrollbar-width: thin;
  max-height: 95vh !important;
}

.custom-dialog-cv .mat-dialog-container {
  padding: 0px;
  width: 88vw;
  height: 95vh;
  scrollbar-width: none !important;
}

.login-dialog .mat-dialog-container {
  scrollbar-width: none !important;
  max-height: 95vh !important;
}

.custom-update-dialog .mat-dialog-container {
  padding: 0px;
  border-radius: 35px;
  /* height: 90vh; */
}

img.ngx-ic-source-image {
  display: inline !important;
}

.custom-select .mat-select {
  background: white;
  border-radius: 4px;
  border: 1px solid var(--color-gray4);
  padding: 3px 8px;
  padding-right: 0px;
}

color-picker .selected-color {
  top: unset !important;
  /* Sobrescribe el top */
}

color-picker .left {
  padding: 22px 8px !important;
  margin-top: 10px !important;

}

color-picker .sliderCursor {
  position: sticky !important;
  width: 205px !important;
}

color-picker .hue-alpha {
  display: contents !important;
}

@media screen and (max-width: 768px) {
  .mat-slide-toggle .mat-slide-toggle-label {
    padding-right: 10px;
  }
}

@font-face {
  font-family: 'Gotham';
  src: url('././assets/fonts/GothamLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('././assets/fonts/GothamMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('././assets/fonts/GothamBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('././assets/fonts/GothamBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Dekar';
  src: url('././assets/fonts/Dekar-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

.heroes {
  font-style: normal;
  font-weight: 900;
  font-family: 'Gotham', sans-serif; /* Asegurar la tipografía */
  
  background: linear-gradient(to bottom, #79D9FC 0%, #79DB9C 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; /* Asegura que el fondo no se expanda fuera del texto */
  padding-top: 30px;
  margin-top: -25px;
}

.stats-text-heroes {
  font-style: normal;
  font-weight: 900;
  font-family: 'Gotham', sans-serif; /* Asegurar la tipografía */
  
  background: linear-gradient(to bottom, #79D9FC 0%, #79DB9C 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; /* Asegura que el fondo no se expanda fuera del texto */
  padding-top: 30px;
  margin-top: -25px;
}